import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "./admin-service";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      cartList: [],
      productList: [],
      search: "",
      cartId: "",
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    this.getCartList()

  },
  methods: {
    async getCartList() {
      let res = await adminService.getCartList("get", this.userId, false)
      console.log(res)
      this.cartId = res[0]?.cart_id;
      this.cartList = res?.map((x) => ({
        Part_Number: x.part_num,
        img: x.img,
        Description: x.part_desc,
        qty: x.qty,
        available_qty: x.available_qty,
        cart_line_key: x.cart_line_id
      }))

    },

    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action)
    },
    increaseQuantity(object, index) {
      console.log(index)
      if (this.cartList[index].qty < this.cartList[index].available_qty) {
        this.cartList[index].qty++;
        this.updateCartDetails(object)
      }

    },
    decreaseQuantity(index) {
      if (this.cartList[index].qty > 0) {
        this.cartList[index].qty--;
      }
      EncryptUtility.localStorageEncrypt("addToCartPart", JSON.stringify(this.productList));
    },

    async onClickSubmitApproval() {
      let obj = {
        user_id: this.userId,
        cart_key: this.cartId,
        ship_key: 1
      }
      let submitObj = { json: JSON.stringify(obj) }
      let res = await adminService.postSubmitOrder("post", submitObj, true)
      console.log(res)

    },

    async updateCartDetails(object) {
      let obj = {
        user_id: this.userId,
        cart_key: object.cart_id,
        cart_line_key: object.cart_line_key
      }
      let submitObj = { json: JSON.stringify(obj) }
      console.log(submitObj)
      let res = await adminService.postCartUpdate("post", submitObj, true)
      console.log(res)

    },
  },


  components: {
    breadcrumbComp,
  }
};